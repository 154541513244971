var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.sourceContent
      ? _c(
          "div",
          {
            staticStyle: { height: "calc(100vh)" },
            attrs: { id: "waterMaskId" },
          },
          [
            _c("iframe", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { src: _vm.sourceContent, allowfullscreen: "true" },
            }),
          ]
        )
      : _c("div", { staticStyle: { "font-size": "16px" } }, [
          _vm._v("暂无课件"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }