<template>
  <div>
    <div v-if="sourceContent" style="height:calc(100vh)"  id="waterMaskId">
      <iframe
        :src="sourceContent"
        allowfullscreen="true"
        style="width: 100%; height: 100%;"
      ></iframe>
    </div>
    <div v-else style="font-size: 16px">暂无课件</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sourceContent: "",
      height: 0,
    };
  },
  created() {
    this.height = window.innerHeight + "px";
    this.sourceContent = this.$route.query.sourceContent;
    this.$nextTick(()=>{
      this.$watermark.set("可达鸭编程",'waterMaskId');
    })
  },
};
</script>
